import React, {useEffect, useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import TourCardGrid from "./TourCardGrid";
import SectionCarouselFleet from "../home/SectionCarouselFleet";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";

const TourMainPage = () => {
    const [title, setTitle] = useState({
        title: "Select among a vast variety of tours",
        description: "Please Find Bellow All Of Our Tours"
    });
    const data = useStaticQuery(graphql`
    {
      allStrapiTours {
        nodes {
          address: tags
          title
          href
          id
          galleryImgs {
            url
          }
          prices {
           price
         }
        }
      }
      allStrapiCarouselFleets(sort: { fields: OrderInCarousel, order: ASC }) {
        nodes {
          title: VehicleCategoryName
          galleryImgs: Images {
            url
          }
          OrderInCarousel
          id: strapiId
        }
      }
    }
  `)

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const asdas =  queryParams.get("pickup");
        if (asdas == "piraeus_port") {
            setTitle({
                title: "Private Tours From Piraeus Port",
                description: "We will pick you up from Piraeus and complete the tour and then drop you off to your desired destination."
            })
        }

    }, []);

    return (
        <div
            className={`nc-ListingExperiencesPage relative overflow-hidden`}
            data-nc-id="ListingExperiencesPage"
        >
            <div className="container relative">

                <div
                    className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative pt-10 lg:pt-20 pb-16`}
                    data-nc-id="SectionHero"
                >
                    <div className="flex flex-col lg:flex-row lg:items-center">
                    </div>
                    <TourCardGrid
                        homePage={false}
                        stayListings={data.allStrapiTours.nodes}
                        heading={title.title} subHeading={title.description}
                    />
                    {/*<SectionSubscribe2 className="py-24 lg:py-32"/>*/}

                    <div className="relative py-16">
                        <SectionCarouselFleet
                            stayListings={data.allStrapiCarouselFleets.nodes}
                            categoryCardType="card4"
                            itemPerRow={4}
                            heading="Our Fleet"
                            subHeading="Find the right vehicle to feet your every need"
                            sliderStyle="style2"
                        />
                    </div>

                    <div className="relative py-16">
                        <SectionHowItWork isCenter={false} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TourMainPage;

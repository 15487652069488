import React, { useEffect, useState } from 'react';
import { SelectPicker } from 'rsuite';
import { Helmet } from 'react-helmet';
import { getCookie, hasCookie, setCookie } from 'cookies-next';

import 'rsuite/dist/rsuite.min.css';

import english from '../../assets/english.jpeg';
import greek from '../../assets/greek.jpeg';
import pdfDocument from '../../assets/Private Tours & Transfers _ Tour-Greece.pdf'; // Import the PDF document

const Translate = () => {
    const languages = [
        { label: 'English', value: '/auto/en' },
        { label: 'Greek', value: '/auto/el' },
        { label: 'Spanish', value: '/auto/es' },
        { label: 'German', value: '/auto/de' },
    ];
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: 'auto',
                    autoDisplay: false,
                    includedLanguages: 'el,en,es,de',
                    layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                },
                'google_translate_element'
            );
        };

        const addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;

        if (hasCookie('googtrans')) {
            setSelected(getCookie('googtrans'));
        } else {
            setSelected('/auto/en');
        }
    }, []);

    const langChange = (e, m, evt) => {
        evt.preventDefault();
        const newSelected = decodeURI(e);

        if (hasCookie('googtrans') && newSelected !== selected) {
            setCookie('googtrans', newSelected);
            setSelected(newSelected);
            window.location.reload();
        } else if (!hasCookie('googtrans')) {
            setCookie('googtrans', newSelected);
            setSelected(newSelected);
            window.location.reload();
        }
    };

    const openPdfInNewTab = () => {
        window.open('https://drive.google.com/file/d/1EX500FDAqoOlELSStTPCw012Gt6MvFEC/view?usp=sharing', '_blank');
    };

    return (
        <>
            <Helmet>
                <script src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" type="text/javascript" async defer></script>
            </Helmet>
            <div className="translateImg overflow-hidden relative top-0 mx-auto w-full flex justify-center " >
                {selected === '/auto/el' ? (
                    <img src={greek} alt="Greek" className="imge z-30 w-3/4 md:w-2/5 cursor-pointer" onClick={openPdfInNewTab} />
                ) : (
                    <img src={english} alt="English" className="imge z-30 w-3/4 md:w-2/5 cursor-pointer" onClick={openPdfInNewTab} />
                )}
            </div>
            <div className="google fixed md:right-16 right-0 top-10 z-50 overflow-hidden">
                <label htmlFor="langPicker" style={{ display: "none" }}>Lang: </label>
                <SelectPicker
                    id="langPicker"
                    data={languages}
                    style={{ width: 100, color: '#1C4456!important' }}
                    placement="bottomEnd"
                    cleanable={false}
                    value={selected}
                    searchable={false}
                    className={'notranslate'}
                    menuClassName={'notranslate'}
                    onSelect={(e, m, evt) => langChange(e, m, evt)}
                    placeholder="EN"
                />
            </div>
        </>
    );
};

export default Translate;

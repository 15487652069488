import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";
// STYLE
import "../styles/index.scss";
import "../index.css";
import "../fonts/line-awesome-1.3.0/css/line-awesome.css";

import React, { useEffect, useState } from "react";
import Footer from "../shared/Footer/Footer";
import PageHome from "../AFolder/home/PageHome";
import CheckOutPage from "../AFolder/BookingProcess/payment/CheckOutPage/CheckOutPage";
import PayPage from "../AFolder/BookingProcess/payment/PayPage/PayPage";
import PageAbout from "../containers/PageAbout/PageAbout";
import TourMainPage from "../AFolder/tours/TourMainPage";
import TermsAndConditions from "../AFolder/home/TermsAndConditions";
import PrivacyPolicy from "../AFolder/home/PrivacyPolicy";
import SEO from "../seo";
import VehicleSelection from "../AFolder/BookingProcess/VehicleSelection/vehicleSelection";
import CustomerDataForm from "../AFolder/BookingProcess/customerDataForm/CustomerDataForm";


//todo create a page just to load localStorage and redirect to common logic.
const pages = [
  {
    path: "/",
    exact: true,
    component: PageHome,
    seoTitle: "Private Tours & Transfers",
    seoDescription: "",
  },
  {
    path: "/#",
    exact: true,
    component: PageHome,
    seoTitle: "Private Tours & Transfers",
    seoDescription: "",
  },
  {
    path: "/tours",
    exact: true,
    component: TourMainPage,
    seoTitle: "Private Tours & Sightseeing",
    seoDescription: "",
  },
  {
    path: "/terms",
    exact: true,
    component: TermsAndConditions,
    seoTitle: "Terms And Conditions",
    seoDescription: "",
  },
  {
    path: "/privacy",
    exact: true,
    component: PrivacyPolicy,
    seoTitle: "Privacy Policy",
    seoDescription: "",
  },
  { path: "/vehicle-selection",
    component: VehicleSelection,
    seoTitle: "Vehicle Selection | Tour-Greece.Gr",
  },
  { path: "/about", component: PageAbout },
  {
    path: "/customer-data-form",
    component: CustomerDataForm,
    seoTitle: "Vehicle Selection For Your Best Comfort",
    seoDescription: "",
  },
  //
  // { path: "/home-1-header-2", exact: true, component: PageHome, seoTitle: "" },
  // { path: "/home-2", component: PageHome2 },
  // //
  // { path: "/listing-stay", component: ListingStayPage, seoTitle: "" },
  // { path: "/listing-stay-map", component: ListingStayMapPage, seoTitle: "" },
  // { path: "/listing-stay-detail", component: ListingStayDetailPage },
  // //
  // {
  //   path: "/listing-experiences",
  //   component: ListingExperiencesPage,
  // },tt
  // {
  //   path: "/listing-experiences-map",
  //   component: ListingExperiencesMapPage,
  // },
  // {
  //   path: "/listing-experiences-detail",
  //   component: ListingExperiencesDetailPage,
  // },
  // //
  // { path: "/listing-car", component: ListingCarPage },
  // { path: "/listing-car-map", component: ListingCarMapPage },
  // { path: "/listing-car-detail", component: ListingCarDetailPage },
  // //
  // { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  // { path: "/listing-real-estate", component: ListingRealEstatePage },
  // //
  // { path: "/listing-flights", component: ListingFlightsPage },
  // //
  {
    path: "/checkout",
    component: CheckOutPage,
    seoTitle: "Checkout",
    seoDescription: "Complete Your Payment Here",
  },
  {
    path: "/pay-done",
    component: PayPage,
    seoTitle: "Payment Completion",
    seoDescription: "",
  },
  //
  // { path: "/author", component: AuthorPage },
  // { path: "/account", component: AccountPage },
  // { path: "/account-password", component: AccountPass },
  // { path: "/account-savelists", component: AccountSavelists },
  // { path: "/account-billing", component: AccountBilling },
  //
  // { path: "/contact", component: PageContact },
  // { path: "/signup", component: PageSignUp },
  // { path: "/login", component: PageLogin },
  // { path: "/subscription", component: PageSubcription },
  //
];
// @ts-ignore
const Pages = ({ location }) => {
  useEffect(() => {
    const toDark = () => {
      const root = document.querySelector("html");
      if (!root) return;
      !root.classList.contains("dark") && root.classList.add("dark");
      localStorage.theme = "dark";
    };
    toDark();
  }, []);


  if (
    location.pathname !== "/" &&
    location.pathname.charAt(location.pathname.length - 1) === "/"
  ) {
    location.pathname = location.pathname.slice(0, -1);
  }
  const Page = pages.filter(({ path }) => path === location.pathname)[0];


  return (
    <div
      className={`bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 h-full`}
    >
      <SEO title={Page?.seoTitle} />
      
      {/* <SiteHeader location={location.pathname} className="divide-y divide-neutral-200 dark:divide-neutral-700 md:divide-y-0" /> */}
      <Page.component />
      {/*<ModalDialog />*/}
      

      {location.pathname !== "/customer-data-form" &&
        location.pathname !== "/vehicle-selection" &&
        location.pathname !== "/checkout" && <Footer />}
    </div>
  );
};

export default Pages;

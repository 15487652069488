import React, {FC, useEffect, useRef, useState} from "react";
import BgGlassmorphism from "AFolder/components/BgGlassmorphism";
import VehicleSelectionSlider from "./VehicleSelectionSlider";
import {graphql, useStaticQuery, navigate} from "gatsby";
import SelectStepsForm from "./SelectStepsForm";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import {REACT_APP_REST_RESOURCE_BASE_END_POINT} from "../../constants/apiEndpoints";
import * as generalMethods from "../generalMethods";

let defaultHeight;
let defaultWidth;

const useWindowSize = () => {
    const [dimensions, setDimensions] = useState({
        windowHeight: defaultHeight,
        windowWidth: defaultWidth,
    });

    useEffect(() => {
        const handler = () =>
            setDimensions({
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth,
            });

        window.addEventListener(`resize`, handler);
        return () => window.removeEventListener(`resize`, handler);
    }, []);

    return dimensions;
};

const VehicleSelection = () => {
    const query = graphql`
    {
      allStrapiVehicleLists(sort: { fields: Order, order: ASC }) {
        nodes {
          EnumId
          id
          CategoryName
          MaxLuggage
          MaxPeople
          rating
          reviews
          Image {
            url
            id
          }
          Carousel_Images {
            url
            id
          }
          Order
          VehicleName
        }
      }
    }
  `;
    const data = useStaticQuery(query);
    const {allStrapiVehicleLists: {nodes: vehicles}} = data;
    //   Hooks
    const scrollAnchor = useRef(null);

    const [state, setState] = useState(null);
    const [suggested, setSuggested] = useState(null);
    const [vehicleList, setVehicleList] = useState(null);
    const [uniqueId, setUniqueId] = useState("web");

    // Handler for fetching price list in case local storage is empty.
    const handleParamPricelist = (query) => {
        let data = JSON.stringify({
            transferDateTime: query.date,
            originPlaceId: query.origin.id,
            destinationPlaceId: query.destination.id,
            adultGuests: query.guests,
        });
        try {
            axios
                .post(
                    `${REACT_APP_REST_RESOURCE_BASE_END_POINT}/prices/placeId`,
                    data,
                    {
                        headers: {"Content-Type": "application/json"},
                    }
                )
                .then((r) => {
                    // Setting local storage
                    let saveOnLocal = JSON.stringify({
                        origin: {
                            id: query.origin.id,
                            title: query.origin.title,
                            // subtitle: origin.subtitle
                        },
                        destination: {
                            id: query.destination.id,
                            title: query.destination.title,
                            // subtitle: destination.subtitle
                        },
                        date: query.date,
                        guests: query.guests,
                        fetchedPrices: r.data,
                    });


                    localStorage.setItem("routeDetailsAndPrices", saveOnLocal);

                    // Only Query Parameters Logic
                    vehicles.forEach((vehicle) => {
                        const vehicleData = r.data.priceList.find(
                            (e) => e.vehicleCategory?.id === vehicle.EnumId
                        );
                        if (vehicleData) {
                            vehicle.price = vehicleData.price;
                            vehicle.uuid = vehicleData.uuid;
                        }
                    });

                    if (windowSize.windowWidth > 640 || window.innerWidth > 640) {
                        setUniqueId("web");
                        //Remove Suggested Vehicle From The Carousel
                        const clonedList = cloneDeep(vehicles);
                        setSuggestedVehicle(query.guests)

                        clonedList.splice(query.guests <= 4 ? 0 : 1, 1);
                        setVehicleList(clonedList);
                    } else {
                        setUniqueId("mobile");
                        setVehicleList(vehicles);
                    }

                    setState(query);
                });
        } catch (error) {
            //todo need an alert modal
            alert(error.response.data);
        }
    };

    // When Size Is bigger than mobile > Use the cloned list
    const windowSize = useWindowSize();

    useEffect(() => {
        // Local Storage Item
        const routeDetailsAndPrices = generalMethods.getRouteDetailsAndPrice();

        // Use queryParams in Case localStorageIs Empty
        // ( I think we used that when someone want to share a url to a friend to book/pay)
        // const queryObj = {
        //     origin: {
        //         id: queryParams.get("origin_id"),
        //         title: queryParams.get("origin_title"),
        //     },
        //     destination: {
        //         id: queryParams.get("destination_id"),
        //         title: queryParams.get("destination_title"),
        //     },
        //     date: new Date(queryParams.get("date")).toISOString(),
        //     guests: parseInt(queryParams.get("guests")),
        // };
        // const queryParams = new URLSearchParams(window.location.search);
        // if (!routeDetailsAndPrices) {
        //     handleParamPricelist(queryObj);
        // }

        if (routeDetailsAndPrices) {
            // Only Local Storage Data Logic
            vehicles.forEach((vehicle) => {
                const vehicleData =
                    routeDetailsAndPrices.fetchedPrices?.priceList?.find(
                        (e) => e.vehicleCategory?.id === vehicle.EnumId
                    );
                if (vehicleData) {
                    vehicle.price = vehicleData.price;
                    vehicle.uuid = vehicleData.uuid;
                } else {
                    vehicle.price = null;
                    vehicle.uuid = null;
                }
            });

            if (windowSize.windowWidth > 640 || window.innerWidth > 640) {
                setUniqueId("web");
                //Remove Suggested Vehicle From The Carousel
                const clonedList = cloneDeep(vehicles);
                const suggestedVehicle = setSuggestedVehicle(routeDetailsAndPrices.guests)

                const removeSuggested = clonedList.filter((item) => item.id !== suggestedVehicle.id);
                const filtered = removeSuggested.filter((item) => item.MaxPeople > routeDetailsAndPrices.guests);

                setVehicleList(filtered);
            } else {
                setUniqueId("mobile");
                const filtered = vehicles.filter((item) => item.MaxPeople > routeDetailsAndPrices.guests);
                setVehicleList(filtered);
            }
            setState(routeDetailsAndPrices);
        }
    }, [windowSize.windowWidth]);


    const setSuggestedVehicle = (guests) => {
        const vehicle = guests <= 4 ? vehicles[0] :
            guests <= 8 ? vehicles[3] : vehicles[5];
        setSuggested(vehicle);

        return vehicle;
    }

    return (
        <div
            className={`nc-VehicleSelectionPage flex flex-col-reverse lg:flex-col relative`}
            data-nc-id="VehicleSelection"
        >
            <BgGlassmorphism/>
            <div
                ref={scrollAnchor}
                className="container relative space-y-10 mb:space-y-24 mb-6 lg:space-y-32 lg:mb-12"
            >
                {/* SEARCH FORM */}
                <div className="relative z-10 mb-0 md:mb-12 lg:mb-0 lg:mt-20 w-full">
                    <div
                        className={`nc-HeroSearchForm w-full my-5 lg:py-0`}
                        data-nc-id="HeroSearchForm"
                    >
                        {/* <VehicleSearchForm haveDefaultValue={state} btnType={"filter"} /> */}
                    </div>
                </div>
                <div className="flex-col items-center w-full relative pt-0 mb:pt-4 pb-16">
                    <div className="relative">
                        <SelectStepsForm
                            className=" lg:-mt-10"
                            vehicle={suggested}
                            stepNumber={1}
                            stepLabel="Vehicle List"
                        />
                    </div>
                    <div className="z-10 mb-12 lg:mb-0 mt-6 lg:mt-10 w-full">
                        <VehicleSelectionSlider
                            uniqueID={uniqueId}
                            vehicleList={vehicleList}
                            subHeading={"Feeling Curious? Here's More"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VehicleSelection;
